<template>
  <div class="printMain body-bg">
    <!-- 导入excel -->
    <!-- <el-dialog :title="$t('i18nn_17a941b7c4c108fe')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
    <!-- <div class="top_action no_print"><el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button></div>
 -->
		<div class="printer-logo"><img src="../assets/logo_en.png" width="80" height="auto" /></div>
    <div style="" v-loading="loading_load">
      <div class="printer_con">
        <h1 class="printer_tit" style="display: flex; justify-content: space-between; margin-bottom: 0;">
					<div style="text-align: left;">
						<div>Transport Shipment List</div>
						<!-- <div v-if="!!$route.query.carTmsType">
							<span>Order Type：</span>
							<span v-if="'2'==$route.query.carTmsType">FBA转运</span>
							<span v-else-if="'1'==$route.query.carTmsType">{{$t('i18nn_34ffc91fe81fa585')}}</span>
							<span v-else-if="'3'==$route.query.carTmsType">{{$t('i18nn_81f6eaf23b9c56eb')}}</span>
							<span v-else>{{$route.query.carTmsType}}</span>
						</div> -->
						<!-- <strong>{{ priterData.cusName }}</strong> -->
					</div>
					
					<div>
						<barcode :code="priterData.outWhCode" :options="{height:50}"></barcode>
					</div>
				</h1>
        <!-- <h2 class="printer_tit2">
          <div class="titleItem">
            <span>order#</span>
            <strong>{{ priterData.sendNo }}</strong>
          </div>
          <div class="titleItem">
            <span>customer#</span>
            <strong>{{ priterData.cusName }}</strong>
          </div>
        </h2> -->
				<table class="simTable printerTable">
					<!-- <tr>
						<td>{{$t('i18nn_6f8f60e16e726ccc')}}</td>
						<th>
							<div v-if="!!$route.query.carTmsType">
								<span v-if="'2'==$route.query.carTmsType">FBA转运</span>
								<span v-else-if="'1'==$route.query.carTmsType">{{$t('i18nn_34ffc91fe81fa585')}}</span>
								<span v-else-if="'3'==$route.query.carTmsType">{{$t('i18nn_81f6eaf23b9c56eb')}}</span>
								<span v-else>{{$route.query.carTmsType}}</span>
							</div>
						</th>
						<td></td>
						<th></th>
					</tr> -->
					<tr>
						<td>是否FBA(IS FBA)</td>
						<th>{{ priterData.isFbaName }}</th>
						<td>客户(Customer)</td>
						<td>{{ priterData.cusName }}</td>
						<!-- <td>出库编号(No.)</td>
						<th>
							{{ priterData.outWhCode }}
						</th> -->
					</tr>
					<!-- <tr>
						<td>是否FBA(is FBA?)</td>
						<th colspan="3">{{ priterData.isFbaName }}</th>
					</tr> -->
					<tr>
						<td>FBA仓库编码(FBA Warehouse)</td>
						<th>
							{{ priterData.fbaAddrCode }}
						</th>
						<td>提交日期(Commit Date)</td>
						<td>{{ priterData.commitDate }}</td>
					</tr>
					<tr>
						
						<td>FBA申请号/物流号/转运单号</td>
						<td>{{ priterData.relationNo }}</td>
						
						<td>{{$t('i18nn_b97a417f08e79456')}}</td>
						<td>{{ priterData.planOutDate}}</td>
					</tr>
					<tr>
						<td>装运类型(Type)</td>
						<td>
							{{ priterData.trTypeName }}
						</td>
						<td></td>
						<td>
							
						</td>
					</tr>
					<tr>
						<td>{{$t('i18nn_8758fd50c87d6c9c')}}</td>
						<td colspan="3">
							{{ priterData.addr }},
							{{ priterData.phone }},
							{{ priterData.city }},
							{{ priterData.state }},
							{{ priterData.country }},
							{{ priterData.postalCode }}
						</td>
					</tr>
					<tr>
						<td>{{$t('15b3627faddccb1d')}}</td>
						<th colspan="3">
							<div class="pre-text">
								{{ priterData.remark }}
							</div>
						</th>
					</tr>
					<tbody style="border-top:2px solid #000;" v-for="(item, index) in priterData.recordList" :key="index">
						
						<tr>
							<td>{{$t('i18nn_73fc38e67381ae73')}}</td>
							<th>{{item.ctnMark}}</th>
							<!-- <td>{{$t('i18nn_a332a38ed3832d9d')}}</td>
							<th>{{item.inWhCode}}</th> -->
							<td>{{$t('i18nn_bdc361ba04506136')}}</td>
							<td>{{item.trCtnCount}}</td>
							
						</tr>
						<tr>
							<td>SKU</td>
							<th v-if="item.trRecordDto">{{item.trRecordDto.goodsSku}}</th>
							
							<!-- <td>{{$t('i18nn_73fc38e67381ae73')}}</td>
							<th>{{item.ctnMark}}</th> -->
							<td>{{$t('i18nn_85a10d3e5ea0e103')}}</td>
							<td v-if="item.trRecordDto">{{item.trRecordDto.ctnSkuCount}}</td>
						</tr>
						<tr>
							<!-- <td>{{$t('i18nn_bdc361ba04506136')}}</td>
							<th>{{item.trCtnCount}}</th> -->
							<td>{{$t('hytxs0000035')}}</td>
							<td v-if="item.trRecordDto">{{item.trRecordDto.place}}</td>
							<td>{{$t('i18nn_bad53577db0da2d3')}}</td>
							<td v-if="item.trRecordDto">{{item.trRecordDto.skuTotal}}</td>
						</tr>
						<!-- <tr>
							<td>{{$t('i18nn_c8773d8d74202801')}}</td>
							<th>{{item.trPallte}}</th>
							<td>备注(Reamrk)</td>
							<td v-if="item.trRecordDto">{{item.trRecordDto.remark}}</td>
						</tr> -->
					</tbody>
				</table>
				
				<div class="tableFooter">
					<ul>
						<li>Picking</li>
						<li>Labeling</li>
						<li>Check</li>
					</ul>
				</div>
        <!-- <table class="simTable printerTable">
          <tr>
            <th width="50">No.</th>
						
            <th>SKU</th>
						<th>SKU Title</th>
						<th>CTN</th>
            <th>Qty</th>
            <th>warehouse</th>
            <th>location</th>
						<th>CNTR No</th>
          </tr>
          <tbody v-if="priterData.records">
            <tr v-for="(item, index) in priterData.records" :key="index" style="">
              <td>{{ index+1 }}</td>
							
              <td>{{item.goodsSku}}</td>
							<td>{{item.goodsNameEn}}&nbsp;|&nbsp;{{item.goodsName}}</td>
							<td>{{item.sendCTN}}</td>
              <td>{{item.plCount}}</td>
              <td>{{item.whNo}}</td>

              <td>{{item.place}}</td>
							<td>{{item.packingNo}}</td>
            </tr>
          </tbody>
        </table> -->
      </div>

      <!-- </div> -->
      <!-- </el-card> -->
    </div>

    <!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button></div> -->
    <!-- </el-dialog> -->
  </div>
</template>
<script>
// import Vue from 'vue';
// import Print from '@/plugins/print.js';
// Vue.use(Print); // 注册
import barcode from '@/components/Common/barcode.vue';

export default {
  // name: 'BuyerOrder',
  //meta信息seo用
  // metaInfo: {
  //   title: 'HYTX-互易天下-转运清单' // set a title
  //   // meta: [{ // set meta
  //   //   name: '互易天下-买家中心-收货账单',
  //   //   content: '互易天下-厂家共享平台-买家中心-收货账单'
  //   // }],
  //   // link: [{                 // set link
  //   //   rel: 'asstes',
  //   //   href: 'https://assets-cdn.github.com/'
  //   // }]
  // },
  // props: ['isDialog'],
  props: {
    // mobile:"",
    // isShow: {
    //   default: function() {
    //     return false;
    //   },
    //   type: Boolean
    // },
    // openTime: {
    //   // default: function() {
    //   //   return '';
    //   // },
    //   // type: String
    // },
    // PrinterData: {
    //   default: function() {
    //     return [];
    //   },
    //   type: Array
    // }
  },
  components: {
  	barcode
  },
  data() {
    return {
      filterData: {
        sendId: ''
      },
      // listUrl: [],
      loading_load: false,
      priterData: {}
    };
  },
  // watch: {
  //   openTime: function(newVal, oldVal) {
  //     console.log('openTime');
  //     this.dialogFile = true;
  //     this.initData();
  //   }
  // },
  //创建时
  created() {
    // this.getPageData();
    if (this.$route.query && this.$route.query.sendId) {
      this.filterData.sendId = this.$route.query.sendId;
    }
    this.initData();
  },
  //编译挂载前
  mounted() {
    // this.initData();
  },
  methods: {
    initData() {
      this.getPageData();
    },
    //打印
    // printerAction() {
    //   window.print();
    //   // this.$print(this.$refs.print);
    // },
    //请求分页数据
    getPageData() {
      this.loading_load = true;

      this.$http
        .put(this.$urlConfig.WhTransferOutWhPrintList, {
					id:this.filterData.sendId
				})
        .then(({ data }) => {
          console.log('列表，请求成功');
          console.log(data);
          
          this.loading_load = false;
          if (200 == data.code) {
            //表格显示数据
            this.priterData = data.data;
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
          }
        })
        .catch(error => {
          console.log(error);
          console.log('列表，请求失败');
          this.$message.error(this.$t('hytxs0000030'));
          this.loading_load = false;
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
// @import '@/assets/css/printer.less'
@import url(../assets/css/printer.less);
</style>
